.nav-tabs .Select {
	width: 220px;
	margin: 7px 0 0 0;
}

/* Utility class to center content horizontally */
.center-block {
	margin: 0 auto;
	float: none;
}
