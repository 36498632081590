/* List view */
.rule .sort-attribute-header .sort-attribute-header-value {
	font-weight: bold;
}
.rule .accordion-header .delete {
	margin-top: -10px;
	margin-right: -10px;
}
.rule .accordion .delete i {
	padding: 0;
}
.rule .accordion .accordion-body {
	padding: 12px;
}
.rule .accordion .accordion-body .card {
	box-shadow: none;
}
.rule .accordion .accordion-body .card-title {
	margin-bottom: 16px;
}
.rule .btn.delete {
	padding: 14px 11px 14px 18px;
}

/* Modal styles */
.rule-sets-table .rule > .row:last-child {
	margin-bottom: 10px;
}
.rule-sets-table .rule > .row:not(:last-child) {
	margin-bottom: 32px;
}
.rule-sets-table .rule h4 {
	margin-bottom: 18px;
}
.rule-sets-table .modal-body {
	padding-left: 24px;
	padding-right: 24px;
}

.modal-body .Select-menu-outer {
	/* Fixes overflow issues of labels with inputs */
	z-index: 10;
}
.modal .condition .form-group {
	margin-bottom: 0;
}

.rule .Select .Select-control .Select-value {
	padding-top: 14px;
}

.rule .Select .Select-control .Select-placeholder {
	padding-top: 6px !important;
	white-space: pre;
}
.rule .Select .Select-option {
	white-space: pre;
}
.rule select.expression {
	text-align-last: center;
}

.sort-info {
	margin-bottom: 12px;
}

.sort-info-btn {
	display: block;
  width: 100%;
  text-align: left;
  padding: 1rem 1rem;
  font-weight: normal;
  color: #0088A9;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.sort-info-btn:hover {
  background-color: #e9ecef;
}

