.rule-sets-table .table tbody td {
	vertical-align: middle;
	padding: 3px 12px;
	/* Allows to use tooltips in all table cells */
	position: relative;
}
.rule-sets-table .table tr > td:first-child,
.table tr > th:first-child {
	padding-left: 15px;
	padding-right: 15px;
}
.rule-sets-table .condition-wrapper {
	background: #edf3f9;
}
.rule-sets-table .sort-info-wrapper {
	background: #e5ebf1;
	white-space: pre;
}

.rule-sets-table .condition .oval {
	display: inline-block;
	font-weight: bold;
	margin-right: 3px;
}
.rule-sets-table tr .condition:not(:first-of-type) {
	margin-left: 10px;
}

.rule-sets-table .drag-handle {
	padding: 5px;
	cursor: grab;
	color: #999;
}
.rule-sets-table .drag-handle:active {
	cursor: grabbing;
}

.rule-sets-table .btn-bottom-margin {
	margin-bottom: 5px;
}

/* Apply different styles when hovering over a row */
.rule-sets-table .drag-handle:active,
.rule-sets-table tr:hover .drag-handle {
	color: #333;
}

.rule-sets-table table .controls {
	min-width: 120px;
	text-align: center;
	/* Removes default padding for centering purposes */
	padding-right: 6px !important;
	padding-left: 6px;
}
.rule-sets-table table .controls button {
	padding: 6px 1px 6px 9px;
	border-radius: 32px;
}

/* Formatting for changes */
.rule-sets-table table tr {
	/* These colors may need to be copied inline or post-processed depending on browser support */
	--colorRemoved: #9f3a38;
	--colorChanged: #493107;
	--backgroundRemoved: #ffe7e7;
	--backgroundChanged: #fff4e4;
	--backgroundAdded: #fffb78;
}
.rule-sets-table table tr.removed td,
.rule-sets-table table tr.changed td,
.rule-sets-table table tr.added td {
	border-color: #fff;
}
.rule-sets-table table tr.changed td,
.rule-sets-table table tr.reordered td,
.rule-sets-table table tr.added td,
.rule-sets-table table tr.removed td {
	font-style: italic;
}
.rule-sets-table table tr.changed td,
.rule-sets-table table tr.reordered td {
	background: var(--backgroundAdded);
}
.rule-sets-table table tr.added td {
	background: var(--backgroundAdded);
}
.rule-sets-table table tr.removed td {
	color: var(--colorRemoved);
	background: var(--backgroundRemoved);
	text-decoration: line-through;
}
.rule-sets-table table tr.removed .tag {
	color: var(--colorRemoved);
}

.rule-sets-table .table {
	/* Used for element positioned as absolute within header */
	position: relative;
}
.rule-sets-table .rule-set-edit {
	padding: 6px;
	position: absolute;
	top: 10px;
	right: 6px;
	font-size: 1.8em;
	outline: none;
}
.rule-sets-table .rule-set-edit:hover,
.rule-sets-table .btn-link.rule-set-edit:active {
	background-color: inherit;
	border-color: transparent;
	outline: none;
}

.rule-sets-table i.info {
	color: #697582;
	margin-left: 6px;
}
.rule-sets-table code.code {
	background: #fff;
	color: #333943;
}
