.condition {
	margin-bottom: 12px;
}

.condition .col-md-3 {
	width: 110px;
	padding-left: 0px;
	padding-right: 0px;
}

.condition .col-md-8 {
	padding-left: 10px;
}

.condition .btn.delete {
	padding: 14px 18px;
}

.condition .form-group.form-group-active label {
	z-index: 0;
}
